@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-table-wrapper {
  max-width: 95% !important;
}

.ant-table  table{
  border-collapse: collapse !important;
}
.ant-table-row {
  border-bottom: solid 1px rgb(128,128,128) !important;
}

.groups-screen .ant-table-cell, .groups-screen .ant-table {
    background: transparent !important;
    color: #fefefe;
    border-bottom: 0px !important;
}
.groups-screen .ant-table-thead > tr > th {
    font-weight: 900;
    color: #fefefe;
}
.groups-screen .ant-table-thead {
    background-color: #191C22 !important;
}
.groups-screen .menuItemContainer {
    margin-left: 10px;
    margin-top: 10px;
    padding-left: 15px;
    padding-top: 15px;
    margin-right: 15px;
    padding-bottom: 15px;
    width: calc(100% - 20px);
    border-radius: 10px;
}
.groups-screen .menuItemContainer.selected {
    background-color: rgb(40 43 47);
}
.groups-screen .menuItemText-icon {
    color: #fefefe;
    margin-right: 10px;
}
.groups-screen .badge {
    padding: 2px 5px;
    background-color: #1C6EF2;
    letter-spacing: normal;
    font-size: 10px;
    border-radius: 10px;
    margin-left: 5px;
}

.groups-screen .stats-container {
    display: flex;
    background-color: #191C22;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    border-radius: 25px;
    margin-bottom: 30px;
}
.groups-screen .stats-inner-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* justify-content: space-between; */
}
.groups-screen .stats-item-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-left: 30px;
}
.groups-screen .stats-header {
    color: #fefefe;
    max-width: 100px;
}
.groups-screen .stats-number {
    color: #fefefe;
}
.groups-screen .stats-header-container {
    color: #fefefe;
    padding: 20px;
    padding-left: 25px;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 2px;
    border-bottom: 1px solid rgb(40 43 47);
    align-items: center;
    display: flex;
}
.groups-screen .stats-item-container {
    display: flex;
    justify-content: center;
    border-left: 1px solid rgb(40 43 47);
}
.groups-screen .stats-item-container.no-border {
    border-left: 0px solid rgb(40 43 47);
}
.groups-screen .stats-number {
    color: #fefefe;
    font-size: 28px;
    font-weight: 400;
}
.groups-screen .stats-header {
    color: #fefefe;
    font-size: 20px;
    font-weight: 700;
}
.groups-screen .stats-sub-header {
    color: #999;
    font-size: 12px;
}
.groups-screen .stats-item-sub-container {
    position: relative;
    align-items: center;
    width: 50%;
}
.groups-screen .borderContainer {
    position: absolute;
    top: 15%;
    left: 0;
    height: 70%;
    width: 5px;
    border-radius: 10px;
}
.groups-screen .borderContainer.red {
    background-color: #FF0056;
}
.groups-screen .borderContainer.yellow {
    background-color: #FF4C00;
}
.groups-screen .borderContainer.blue {
    background-color: #0093FF;
    margin-left: 2px;
}

.groups-screen .rightContainerHeader-sub {
    color: #999;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: normal  ;
}

.groups-screen .main-center-container {
    display: flex;
    flex-direction: row;
}
.main-center-right-container {
    display: flex;
    background-color: #191C22;
    flex-direction: column;
    flex: 1 1;
    border-radius: 5px;
  margin: 16px;
    padding: 10px;
    max-height: 80vh;

}
.main-center-right-container .ant-timeline-item-label, .main-center-right-container  .ant-timeline-item-content {
    color: #fefefe;
}
.groups-screen .ant-table-row {
    cursor: pointer;
}
.ant-modal-footer {
    display: none;
}
.groups-screen .ant-modal-header, .groups-screen .ant-modal-body {
    padding: 0px !important;
}
.ant-modal-content {
    background-color: transparent;
}
.modal-loading-container {
    display: flex;
    flex: 1 1;
    color: #fefefe;
    justify-content: center;
    align-items: center;
}

.users_input {
    width: 95%;
    background-color: rgba(25, 28, 34, 0.3);
    border: 1px solid rgba(38, 42, 49, 0.9);
    margin: 10px;
    padding: 15px;
    border-radius: 20px;
    color: #fefefe;
}

html {
  scrollbar-face-color: #646464;
  scrollbar-base-color: #646464;
  scrollbar-3dlight-color: #646464;
  scrollbar-highlight-color: #646464;
  scrollbar-track-color: #000;
  scrollbar-arrow-color: #000;
  scrollbar-shadow-color: #646464;
  scrollbar-dark-shadow-color: #646464;
}

::-webkit-scrollbar { width: 8px; height: 3px;}
::-webkit-scrollbar-button {  background-color: #666; }
::-webkit-scrollbar-track {  background-color: #646464;}
::-webkit-scrollbar-track-piece { background-color: #000;}
::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px;}
::-webkit-scrollbar-corner { background-color: #646464;}
::-webkit-resizer { background-color: #666;}

.groups-screen .ant-table-cell, .groups-screen .ant-table {
    background: transparent !important;
    color: #fefefe;
    border-bottom: 0px !important;
}
.groups-screen .ant-table-thead > tr > th {
    font-weight: 900;
    color: #fefefe;
}
.groups-screen .ant-table-thead {
    background-color: #191C22 !important;
}
.groups-screen .menuItemContainer {
    margin-left: 10px;
    margin-top: 10px;
    padding-left: 15px;
    padding-top: 15px;
    margin-right: 15px;
    padding-bottom: 15px;
    width: calc(100% - 20px);
    border-radius: 10px;
}
.groups-screen .menuItemContainer.selected {
    background-color: rgb(40 43 47);
}
.groups-screen .menuItemText-icon {
    color: #fefefe;
    margin-right: 10px;
}
.groups-screen .badge {
    padding: 2px 5px;
    background-color: #1C6EF2;
    letter-spacing: normal;
    font-size: 10px;
    border-radius: 10px;
    margin-left: 5px;
}

.groups-screen .stats-container {
    display: flex;
    background-color: #191C22;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    border-radius: 25px;
    margin-bottom: 30px;
}
.groups-screen .stats-inner-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* justify-content: space-between; */
}
.groups-screen .stats-item-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-left: 30px;
}
.groups-screen .stats-header {
    color: #fefefe;
    max-width: 100px;
}
.groups-screen .stats-number {
    color: #fefefe;
}
.groups-screen .stats-header-container {
    color: #fefefe;
    padding: 20px;
    padding-left: 25px;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 2px;
    border-bottom: 1px solid rgb(40 43 47);
    align-items: center;
    display: flex;
}
.groups-screen .stats-item-container {
    display: flex;
    justify-content: center;
    border-left: 1px solid rgb(40 43 47);
}
.groups-screen .stats-item-container.no-border {
    border-left: 0px solid rgb(40 43 47);
}
.groups-screen .stats-number {
    color: #fefefe;
    font-size: 28px;
    font-weight: 400;
}
.groups-screen .stats-header {
    color: #fefefe;
    font-size: 20px;
    font-weight: 700;
}
.groups-screen .stats-sub-header {
    color: #999;
    font-size: 12px;
}
.groups-screen .stats-item-sub-container {
    position: relative;
    align-items: center;
    width: 50%;
}
.groups-screen .borderContainer {
    position: absolute;
    top: 15%;
    left: 0;
    height: 70%;
    width: 5px;
    border-radius: 10px;
}
.groups-screen .borderContainer.red {
    background-color: #FF0056;
}
.groups-screen .borderContainer.yellow {
    background-color: #FF4C00;
}
.groups-screen .borderContainer.blue {
    background-color: #0093FF;
    margin-left: 2px;
}

.groups-screen .rightContainerHeader-sub {
    color: #999;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: normal  ;
}

.groups-screen .main-center-container {
    display: flex;
    flex-direction: row;
}
.main-center-right-container {
    display: flex;
    background-color: #191C22;
    flex-direction: column;
    flex: 1 1;
    border-radius: 5px;
  margin: 16px;
    padding: 10px;
    max-height: 80vh;

}
.main-center-right-container .ant-timeline-item-label, .main-center-right-container  .ant-timeline-item-content {
    color: #fefefe;
}
.groups-screen .ant-table-row {
    cursor: pointer;
}
.ant-modal-footer {
    display: none;
}
.groups-screen .ant-modal-header, .groups-screen .ant-modal-body {
    padding: 0px !important;
}
.ant-modal-content {
    background-color: transparent;
}
.modal-loading-container {
    display: flex;
    flex: 1 1;
    color: #fefefe;
    justify-content: center;
    align-items: center;
}

.users_input {
    width: 95%;
    background-color: rgba(25, 28, 34, 0.3);
    border: 1px solid rgba(38, 42, 49, 0.9);
    margin: 10px;
    padding: 15px;
    border-radius: 20px;
    color: #fefefe;
}

html {
  scrollbar-face-color: #646464;
  scrollbar-base-color: #646464;
  scrollbar-3dlight-color: #646464;
  scrollbar-highlight-color: #646464;
  scrollbar-track-color: #000;
  scrollbar-arrow-color: #000;
  scrollbar-shadow-color: #646464;
  scrollbar-dark-shadow-color: #646464;
}

::-webkit-scrollbar { width: 8px; height: 3px;}
::-webkit-scrollbar-button {  background-color: #666; }
::-webkit-scrollbar-track {  background-color: #646464;}
::-webkit-scrollbar-track-piece { background-color: #000;}
::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px;}
::-webkit-scrollbar-corner { background-color: #646464;}
::-webkit-resizer { background-color: #666;}


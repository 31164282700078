@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap');
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-table-wrapper {
  max-width: 95% !important;
}

.ant-table  table{
  border-collapse: collapse !important;
}
.ant-table-row {
  border-bottom: solid 1px rgb(128,128,128) !important;
}
